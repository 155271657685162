/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";
import Stack from '@mui/material/Stack';
// @mui material components
import Icon from "@mui/material/Icon";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import MKInput from "components/MKInput"; 
import MKBox from "components/MKBox"; 
import MKButton from "components/MKButton"; 
import MKTypography from "components/MKTypography"; 
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import MKAlert from "components/MKAlert";
import CircularProgress from '@mui/material/CircularProgress';
 
// Authentication pages components
import AppLayout from "components/Layouts/AppLayout";
 
import { appAuth } from "../contexts/AppContext"
 

function Apps() {

  const navigate = useNavigate() 
  const {  allApps, getAllApp, createApp, isLoggedIn } = appAuth() 
 
  const appNameRef = useRef()
 
  const [modalState, setModalState] = useState({createApp:false, showPlan:false});
  const [loadingApp, setLoadingApp] = useState(false);

  const [modalData, setModalData] = useState({});

  const toggleModal = (type, close) => {

    if(close){
      setModalState({...modalState , [type]:!modalState[type], [close]: !modalState[close]}); 
    }
    else setModalState({...modalState , [type]:!modalState[type]}); 
  }
 
  const [errorMessage, setErrorMessage] = useState(""); 
  const renderRef = useRef(false) 

  useEffect(() => {

   
    if (!isLoggedIn() ){
      navigate("/signin")
      return
    } 

    if (renderRef.current === false) { 

      getAllApp()

      return () => {
        renderRef.current = true
        console.log("Apps render clean up. ")
      }

    }
     
  }, []);

 
 

  async function gotoApp(app) {
    navigate(`/app/${app.appId}`)
  }


  async function createAppHandler(){

   
    setLoadingApp(true)
    toggleModal("createApp")
    let result  = await createApp(appNameRef.current.value);
    setLoadingApp(false)

    console.log("createAppHandler result = ", result)

    if(result && result.error && result.error.message){
      if(result.error.code === 417) {
        setModalData(result.error)
        toggleModal("showPlan", "createApp")
        return;
      }
      showError(result.error.message);
    }
    
  }

  function showError(message){
    setErrorMessage(message);

    setTimeout(() => {
      setErrorMessage("");
    }, 5000);

  }

  const gotoProfilePlan = () => {
    toggleModal("createApp")
    navigate(`/profile?showPlan=true`)
  }

  const renderApps = () =>
   
    allApps.map( app => (
    
            <MKBox onClick={() => app.status !== "pending" && gotoApp(app)}
              key={app.appId} 
              width="100%"
              height="5rem"
              variant="gradient"
              bgColor="info"
              color="white"
              coloredShadow="info"
              display="flex"
              alignItems="center"
              justifyContent="left"
              sx={{ cursor: "pointer" }}
              borderRadius="xl">
                <MKTypography variant="h5" color="white" px={2}>{app.name}</MKTypography> 
                {app.status === "pending" &&  <div class="dotloader"></div> }

                <MKBox  mx="auto" justifyContent="right"  display="flex" width="100%" > 

                  <MKTypography variant="h6"  pt={0.5}>Detail</MKTypography>
                  <Icon   fontSize= "large" >arrow_right</Icon>
                </MKBox>
            </MKBox> 
      
  ));


  return (
    <> 
    <AppLayout>

      
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11} lg={9}> 

        {errorMessage != "" && <MKAlert color="error" dismissible>{errorMessage}</MKAlert>}

          <MKBox mx="auto" pb={2} display="flex" justifyContent="right" >
            {loadingApp ?  <MKButton variant="gradient" color="info">
                          loading... <CircularProgress  color="primary" size={14}/>
                        </MKButton> :
                    <MKButton variant="gradient" color="primary" onClick={() => toggleModal("createApp")}>
                      create new app
                    </MKButton>
            }
          </MKBox>


          <Stack spacing={2} alignItems="center">  
            {renderApps()} 
          </Stack> 

          
        </Grid> 
      </Grid> 



      <Modal open={modalState.showPlan}  onClose={ () => toggleModal("showPlan")} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={modalState.showPlan} timeout={300}>
        <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
          <MKBox
            position="relative"
            sx={{ width: '95%' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            
            <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Required Account Upgrade</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={ () => toggleModal("showPlan")} />
            </MKBox>
            
            <Divider sx={{ my: 0 }} />
            
            <MKBox display="flex" justifyContent="center" p={2}>
              <MKTypography variant="h6">{modalData.message}</MKTypography>
            </MKBox>

            <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={ () => toggleModal("showPlan")}>
                  close
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={gotoProfilePlan}>
                  Upgrade
                </MKButton>
              </MKBox>

          </MKBox>
          </Grid>
        </Slide>
      </Modal>

      
      <Modal open={modalState.createApp} onClose={ () => toggleModal("createApp")} sx={{ display: "grid", placeItems: "center" }}>
          
            <MKBox
              position="relative"
              sx={{ minWidth:300 , maxWidth: 'lg' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Create Application</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={ () => toggleModal("createApp")} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKInput
                  id="appNameRef"
                  variant="standard"
                  label="App Name"
                  inputRef={appNameRef} 
                  required  
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </MKBox> 
              
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={ () => toggleModal("createApp")}>
                  close
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={createAppHandler}>
                  Create
                </MKButton>
              </MKBox>
            </MKBox>
         
        </Modal>
       

      </AppLayout>
    </>

  );


}

export default Apps;
